.App {
  text-align: center;
}
body, html {
  margin: 0;
  padding: 0;
  background: linear-gradient(120deg, #afc7f7, #9abcef);
  font-family: 'Arial', sans-serif; /* Introducing a generic font family */
  font-size: 18px;
  height: 100%;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin-right: 20px;
}

nav li a {
  text-decoration: none;
  color: #303c50;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

nav li a:hover {
  background-color: #e6ebfb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #303949;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Website {
  background-color: #203a68;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(241, 24, 24);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.niceButton {
  background-color: #272b6d;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
}

.niceButton:hover {
  background-color: #3516c0;
  transform: scale(1.05); 
}
.myImageClass {
    max-width: 300px;
    height: 300px;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 100px;
    border-radius: 20%;  /* Making the image circular */
    box-shadow: 0 4px 12px rgba(13, 12, 12, 0.1);
}
