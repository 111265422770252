/* DisplayBoard.css */

.posts {
    margin-top: 20px;
  }
  
  .post {
    border: 1px solid black;
    padding: 10px;
    margin: 10px 0;
  }
  